import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Button from '../components/Button'
import { connect} from 'react-redux'
import {
    openEditDailog,
    selectTower,
    selectFloor,
    selectRoomNo,
    updateRoom,
 } from '../actions/BookingActions'

class EditDialog extends Component {
    
    render() {

        const {
            classes, 
            open,
            handleClose,
            selectTower,
            selectRoomNo,
            updateRoomData
        } = this.props
        
        const {
            updateTower,
            updateFloor,
            rooms
        } = updateRoomData

        // console.log('---------- editdialog prop -----------')
        // console.log(updateRoomData)
        // console.log('---------- editdialog prop -----------')


        return(
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
            >
            <DialogTitle>Update Room</DialogTitle>

            <DialogContent>

                <form className={classes.container}>
                    <FormControl className={classes.formControl}>

                        <InputLabel htmlFor="age-native-simple">Tower</InputLabel>
                        <Select
                            native
                            onChange={(event) => selectTower(event.target.value)}
                            input={<Input id="age-native-simple" />}
                            >
                                <option value="all" >All</option>
                                <option value="A1">A1</option>
                                <option value="B2">B2</option>
                                
                        </Select>
                    </FormControl>

                    <FormControl className={classes.formControl}>

                        <InputLabel htmlFor="age-native-simple">Floor</InputLabel>
                            <Select
                                native
                                disabled={!updateTower.length > 0}
                                onChange = {(event) => this.handleSelectFloor(event)}
                                input={<Input id="age-native-simple" />}
                                >
                                    <option value="all" >All</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    
                            </Select>
                    </FormControl>

                    <FormControl className={classes.formControl}>

                        <InputLabel htmlFor="age-native-simple">Room Number</InputLabel>
                            <Select
                                native
                                disabled={!updateFloor.length > 0}
                                input={<Input id="age-native-simple" />}
                                onChange={(event) => selectRoomNo(event.target.value)}
                                >
                                    <option value="all" >All</option>
                                    {
                                        rooms.map( (prop, key ) => {
                                               return(
                                                <option value={prop.room_number}>{prop.room_number}</option>
                                               ) 
                                        })
                                    }   
                            </Select>
                    </FormControl>
                </form>
            </DialogContent>

            <DialogActions>
                <Button onClick={()=> handleClose(false)} color="danger">
                    Cancel
                </Button>
                <Button onClick={() => this.handleUpdateRoom()} color="info">
                    Update
                </Button>
          </DialogActions>

            </Dialog>
        )
    }

    handleUpdateRoom= () =>{

        const {updateRoomData, handleClose, updateRoom } = this.props

        handleClose(false)

        const {
                updateConfirmationNumber,
                updateTower,
                updateFloor,
                updateRoomNo,
            } = updateRoomData

        updateRoom({
            confirmation_number: updateConfirmationNumber , 
            tower: updateTower, 
            floor: Number(updateFloor), 
            room_number: Number(updateRoomNo)})

    }

    handleSelectFloor = (event) => {

        const floor = event.target.value

        const { updateRoomData, selectFloor} = this.props

        const { updateTower } = updateRoomData

        selectFloor( {tower: updateTower, floor: floor })
    }
}

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing.unit,
      minWidth: 120,
    },
});

EditDialog.propsTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {
    return {
        rooms: state.mainDashboard.rooms,
        open: state.bookingData.openEditDialog,
        updateRoomData: state.bookingData
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleClose: (open) => {
            dispatch(
                openEditDailog(open)
            )
        },
        selectTower: (tower) => {
            dispatch(
                selectTower(tower)
            )
        },
        selectFloor: ({tower, floor}) => {
            dispatch(
                selectFloor({tower, floor})
            )
        },
        selectRoomNo: (room_no) => {
            dispatch(
                selectRoomNo(room_no)
            )
        },
        updateRoom: (props) => {
            dispatch(
                updateRoom(props)
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditDialog));