import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton"
import { 
  Edit
} from '@material-ui/icons'
import Select from "@material-ui/core/Select"
//import Danger from "../../components/typography/Danger"
//import Info  from "../../components/typography/Info"
// core components
import tableStyle from "../../assets/components/tableStyle";


function CustomTable({ ...props }) {

   const { classes, tableHead, tableData, tableHeaderColor, onItemClick, chnageStatus, updateDeposit } = props;
  //const { classes, tableHead, tableData, tableHeaderColor, chnageStatus  } = props;

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
              // console.log(prop.updated_at)
            return (
              <TableRow key={key} >

                <TableCell className={classes.tableCell}>
                  {prop.confirmation_number}
                </TableCell>

                <TableCell className={classes.tableCell}>
                  { prop.name }
                </TableCell>

                <TableCell className={classes.tableCell}>
                { prop.nrc }
                </TableCell>

                <TableCell className={classes.tableCell}>
                  {prop.type}
                </TableCell>
                
                <TableCell className={classes.tableCell}>
                { prop.deposit.toLocaleString()+" MMK" }
                </TableCell>

                <TableCell>
                <IconButton onClick={ (event) => updateDeposit(prop)} disabled= {prop.status === 'completed' || prop.status === 'canceled'}>
                    <Edit/>
                  </IconButton>
                </TableCell>

                <TableCell className={classes.tableCell}>
                { prop.tower }
                </TableCell>

                <TableCell className={classes.tableCell}>
                { prop.floor }
                </TableCell>

                <TableCell className={classes.tableCell}>
                { prop.room_number }
                </TableCell>
                
                {/* <TableCell>
                  { 
                    prop.updated_at === undefined ? (
                      <div>---</div>
                    ):(
                      prop.updated_at
                    )
                  }
                </TableCell>

                <TableCell>
                  {
                    prop.updated_by === undefined ? (
                      <div>---</div>
                    ):(
                      prop.updated_by
                    )
                  }
                </TableCell> */}

                <TableCell className={classes.tableCell}>

                  {/* {
                    prop.status === 'completed' ? (
                      
                      // <Danger> Completed </Danger>
                      <Info>Completed</Info>

                    ):( */}
                      <Select
                            native
                            value={prop.status}
                            onChange={chnageStatus(prop)}
                            name="status"
                            inputProps={{
                              id: 'age-native-required',
                          }}>
                            <option value=""/>
                            <option className={{color:""}} value="pending">Pending</option>
                            <option value="canceled">Canceled</option>
                            <option value="completed">Completed</option>
                        </Select>
                    {/* ) */}
                  {/* } */}
                  
                </TableCell>

                <TableCell>
                  <IconButton onClick={ (event) => onItemClick(prop)} disabled= {prop.status === 'completed'}>
                    <Edit/>
                  </IconButton>
                </TableCell>

              </TableRow>

            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}


CustomTable.defaultProps = {
  tableHeaderColor: "gray"
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object)
};
//tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
export default withStyles(tableStyle)(CustomTable);
