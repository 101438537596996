const nrc_data = { 
    nrc_pre_num: ['1', '2', '3', '4', '5', '6', '7', '8', '9','10','11','12', '13', '14'],
    nrc_body: [
                {id:1, data: 'Ah'},
                {id:2, data: 'Au'}, 
                {id:3, data: 'Ba'},
                {id:4, data: 'Da'},
                {id:5, data: 'Fa'},
                {id:6, data: 'Ga'},
                {id:7, data: 'Ha'},
                {id:8, data: 'Hta'},
                {id:9, data: 'Ka'},
                {id:10, data: 'Kha'},
                {id:11, data: 'La'},
                {id:12, data: 'Ma'},
                {id:13, data: 'Na'},
                {id:14, data: 'Nya'},
                {id:15, data: 'Nga'},
                {id:16, data: 'Oo'},
                {id:17, data: 'Ou'},
                {id:18, data: 'Pa'},
                {id:19, data: 'Pha'},
                {id:20, data: 'Sa'},
                {id:21, data: 'San'},
                {id:22, data: 'Sha'},
                {id:23, data: 'Sa'},
                {id:24, data: 'Ta'},
                {id:25, data: 'Tha'},
                {id:26, data: 'Wa'},
                {id:27, data: 'Ya'},
                {id:28, data: 'Za'},
            ],
    national: ['(N)','(P)', '(E)']
}

export default nrc_data