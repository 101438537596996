import axios from 'axios'
import { 
    LOAD_BOOKING,
    UPDATE_RELOAD_STATUS,
    ERROR,
    UNAUTHORIZED,
    CHANGE_CONFIRMATION_NUMBER,
    OPEN_EDIT_DIALOG,
    SELECT_TOWER,
    SELECT_FLOOR,
    SELECT_ROOM_NO,
    CLOSE_ERROR_DIALOG,
    OPEN_UPDATE_DEPOSIT_DIALOG,
    CHANGE_DEPOSIT,
    UPDATE_DEPOSIT,
    AVAILABLE_ROOMS
} from './types'
import { 
    GET_BOOKINGS,
    UPDATE_BOOKING,
    UPDATE_ROOM,
    API_UPDATE_DEPOIST,
    AVAILABLE_ROOM
} from '../api/EndPoint'
import {
    JWT,
    RESPONSE_CODE_SUCCESS,
    RESPONSE_CODE_UNAUTHORIZED,
} from '../utils/constants'

import * as Sentry from '@sentry/browser';

Sentry.init({
     dsn: "https://2ea929333d364fcaacced20b2c7229cf@sentry.io/1337445"
    });


export const loadBooking = (props) => dispatch => {

    if(props !== undefined){
        removeEmpty(props)
    }

    dispatch({
        type: UPDATE_RELOAD_STATUS,
        payload: false
    })

    axios({
        method: 'get',
        headers: {
            'token': sessionStorage.getItem(JWT),
        'Content-Type': 'application/json'
        },
        withCredentials: true,
        mode: 'no-cors',
        url: GET_BOOKINGS,
        params: props
    }).then( response => {

          //  console.log(response)

            if(response.data.code === RESPONSE_CODE_SUCCESS){

                dispatch({
                    type: LOAD_BOOKING,
                    payload: response.data.payload
                })
            }
            

        }).catch( error => {

        if( error.response.status === RESPONSE_CODE_UNAUTHORIZED ){
            dispatch({ type: UNAUTHORIZED, payload: true})
        }else{
            dispatch({ type: ERROR, payload: true})
        }

    })

}  

const removeEmpty = (obj) => {
    Object.keys(obj).forEach((key) => (obj[key] === '') && delete obj[key]);
}

export const updateStatus = ( props ) => dispatch => {
    // console.log('========== call update status ============')
    // console.log(props)

    axios({
        method: 'post',
        headers: {
            'token': sessionStorage.getItem(JWT),
        'Content-Type': 'application/json'
        },
        withCredentials: true,
        mode: 'no-cors',
        url: UPDATE_BOOKING,
        data: props
    }).then( response => {

        if(response.data.code === RESPONSE_CODE_SUCCESS){

            dispatch({
                type: UPDATE_RELOAD_STATUS,
                payload: true
            })

        }

    }).catch(error => {

        if( error.response.status === RESPONSE_CODE_UNAUTHORIZED ){
            dispatch({ type: UNAUTHORIZED, payload: true})
        }else{


            dispatch({ type: ERROR, payload: true})
        }

    })

}

export const changeConfirmationNumber = (text) => dispatch => {

    dispatch({
        type: CHANGE_CONFIRMATION_NUMBER,
        payload: text
    })

}

export const openEditDailog = (prop) => dispatch => {
    dispatch({
        type: OPEN_EDIT_DIALOG,
        payload: prop
    })
}

export const selectTower = (tower) => dispatch => {
    dispatch({
        type: SELECT_TOWER,
        payload: tower
    })
}

export const selectFloor = (props) => dispatch => {

    dispatch({
        type: SELECT_FLOOR,
        payload: props.floor
    })

    loadAvailableRooms(props, dispatch)

}

export const selectRoomNo = (room_no) => dispatch => {
    dispatch({
        type: SELECT_ROOM_NO,
        payload: room_no
    })
}

export const updateRoom = (props) => dispatch => {

    axios({
        method: 'post',
        headers: {
            'token': sessionStorage.getItem(JWT),
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        mode: 'no-cors',
        url: UPDATE_ROOM,
        data: props
    }).then( response => {

        if(response.data.code === RESPONSE_CODE_SUCCESS){
            dispatch({
                type: UPDATE_ROOM,
                payload: true
            })
        }
    }).catch( error => {

      //  console.log(error.response)

        if( error.response.status === RESPONSE_CODE_UNAUTHORIZED ){
            dispatch({ type: UNAUTHORIZED, payload: true})
        }else{

            Sentry.captureException(error)

            dispatch({ type: ERROR, payload: {error: true, message: error.response.data.message}})
        }

    })

}

export const closeErrorDialog = (close) => dispatch => {
    dispatch({
        type: CLOSE_ERROR_DIALOG,
        payload: close
    })
}

export const openUpdateDepositDialog = (prop) => dispatch => {
    dispatch({
        type: OPEN_UPDATE_DEPOSIT_DIALOG,
        payload: prop
    })
}

export const handleChangeDeposit = (text) => dispatch => {
    dispatch({
        type: CHANGE_DEPOSIT,
        payload: Number(text)
    })
}

export const updateDeposit = (props) => dispatch => {

    axios({
        method: 'post',
        headers: {
            'token': sessionStorage.getItem(JWT),
        'Content-Type': 'application/json'
        },
        withCredentials: true,
        mode: 'no-cors',
        url: API_UPDATE_DEPOIST,
        data: props
    }).then( response => {

        if(response.data.code === RESPONSE_CODE_SUCCESS){

            dispatch({
                type: UPDATE_DEPOSIT,
                payload: true
            })

        }

    }).catch(error => {

        if( error.response.status === RESPONSE_CODE_UNAUTHORIZED ){
            dispatch({ type: UNAUTHORIZED, payload: true})
        }else{
            dispatch({ type: ERROR, payload: true})
        }

    })

}


const loadAvailableRooms = (props, dispatch) => {

    removeEmpty(props)

    axios({
        method: 'get',
        mode: 'no-cors',
        url: AVAILABLE_ROOM,
        headers: {
            'token': sessionStorage.getItem(JWT),
            'Content-Type': 'application/json'
            },
        params: props
    }).then( response => {

        // console.log(response.data)

        const { code, payload  } = response.data;

        if( code === RESPONSE_CODE_SUCCESS ){

            dispatch({
                type: AVAILABLE_ROOMS,
                payload: payload
            })

        }
       
    }).catch( error => {

        // console.log("-------------------------")
        // console.error(error.response)
        // console.log("-------------------------")

        if( error.response.status === RESPONSE_CODE_UNAUTHORIZED ){
            dispatch({ type: UNAUTHORIZED, payload: true})
        }else{
            dispatch({ type: ERROR, payload: true})
        }

        
    })


}


