import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    withStyles
} from '@material-ui/core/styles'
import {
    showMenu
} from '../../actions/MainAction'
import {
    loadUser,
    loadNrcData,
    openAddDialog,
    handleNrcPreNumber,
    handleNrcBody1,
    handleNrcBody2,
    handleNrcBody3,
    handleNrcNationality,
    handleNrcNumber,
    handleCustomerName,
    handleEligableUnit,
    addUser,
    doLogout
} from '../../actions/UserActions'
import {
    connect
} from 'react-redux'
import Card from '../../components/Card'
import CardBody from '../../components/CardBody'
import Button from '../../components/Button'
import UserTable from './UserTable'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridContainer from '../../components/grid/GridContainer'
import GridItem from '../../components/grid/GridItem'
import CustomInput from '../../components/CustomInput'
import UnauthorizDialog from '../../components/UnauthorizeDialog'
import { 
    Select
}from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import {
    JWT
} from '../../utils/constants'
import * as Sentry from '@sentry/browser';

Sentry.init({
     dsn: "https://2ea929333d364fcaacced20b2c7229cf@sentry.io/1337445"
});


class User extends Component {

    componentDidMount(){
        this.props.showMenu(true)
        this.props.loadUser()
        this.props.loadNrcData()
    }

    componentDidCatch(error, errorInfo){

        Sentry.withScope( scope => {
            
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key])
            })
            
            Sentry.captureException(error)

        })

    }

        render(){

            const { classes, userData, loadUser} = this.props
            // console.log('--------- user --------')
            // console.log(this.props)
            // console.log('--------- user --------')
            const { users, nrcData, openAddUserDialog, nrcPreNumber,
            nrcBody1,
            nrcBody2,
            nrcBody3,
            nrcNatioanlity,
            nrc,
            name,
            eligable_unit,
            addUserSuccess,
            unauthorized,
            logout
        } = userData

            const enableAddButton = nrcPreNumber.length > 0 && nrcBody1.length > 0 &&
                                    nrcBody2.length > 0 && nrcBody3.length > 0 &&
                                    nrcNatioanlity.length > 0 && nrc.length > 0 &&
                                    name.length > 0 && eligable_unit > 0
            
            if(addUserSuccess){
                loadUser()
            }

            return(
                <Card>
                    
                    <UnauthorizDialog 
                        open={unauthorized}
                        handleClose = { this.redirctToLogin }
                    />

                    {
                        logout && (
                            <Redirect from='admin/dashboard' to='admin/login' />
                        )
                    }

                    <div className= {classes.filerContainer}>
                        <div className={classes.filter}>
                            <Button   
                                color="info"
                                onClick= { (event) => this.handleOpenAddUserDialog()}>
                                Add Customer
                            </Button>
                        </div>
                    </div>

                    <CardBody>

                        <UserTable
                            tableHeaderColor="warning"
                            tableHead={["Name", "NRC", "Booking Number", "Eligable Unit", "Booked Unit"]}
                            tableData={users}
                        />

                    </CardBody>

                    <Dialog
                        open={openAddUserDialog}>

                        <DialogTitle id="form-dialog-title">Add Customer</DialogTitle>

                        <DialogContent>

                            <GridContainer>
                                
                            <GridItem xs={12} sm={12} md={12}>
                                <h4>NRC:</h4>
                            </GridItem>

                            <GridItem  xs={12} sm={12} md={12}>
                            
                                <Select
                                    className={classes.gridItem}
                                    native
                                    onChange={this.onChangeNrcPreNumber.bind(this)}
                                    inputProps={{
                                    name: 'tower',
                                    id: 'age-native-simple',
                                    }}
                                    fullWidth={true}
                                >
                                    <option value='' >Select</option>
                                    {
                                        nrcData.nrc_pre_num.map((prop, key) => {
                                           return(<option value={prop} key={key}>{prop}</option>)
                                        }

                                        )
                                    }
                                   
                                   
                                </Select>
                            
                            </GridItem>

                            <GridItem className={classes.gridItem} xs={12} sm={4} md={4}>
                            
                                <Select
                                    className={classes.gridItem}
                                    onChange={this.onChangeNrcBody1.bind(this)}
                                    native
                                    inputProps={{
                                    name: 'tower',
                                    id: 'age-native-simple',
                                    }}
                                    fullWidth={true}
                                >
                                    <option value='' >Select</option>
                                    {
                                        nrcData.nrc_body.map((prop, key) => {
                                        return(<option value={prop.data} key={key}>{prop.data}</option>)
                                        }

                                        )
                                    }
                                
                                
                                </Select>
                        
                            </GridItem>

                            <GridItem xs={12} sm={4} md={4}>
                            
                                <Select
                                    className={classes.gridItem}
                                    onChange= {this.onChangeNrcBody2.bind(this)}
                                    native
                                    inputProps={{
                                    name: 'tower',
                                    id: 'age-native-simple',
                                    }}
                                    fullWidth={true}
                                >
                                    <option value='' >Select</option>
                                    {
                                        nrcData.nrc_body.map((prop, key) => {
                                        return(<option value={prop.data} key={key}>{prop.data}</option>)
                                        }

                                        )
                                    }
                                
                                
                                </Select>
                    
                        </GridItem>

                        <GridItem xs={12} sm={4} md={4}>
                            
                            <Select
                                native
                                onChange={this.onChangeNrcBody3.bind(this)}
                                className={classes.gridItem}
                                inputProps={{
                                name: 'tower',
                                id: 'age-native-simple',
                                }}
                                fullWidth={true} 
                            >
                                <option value='' >Select</option>
                                {
                                    nrcData.nrc_body.map((prop, key) => {
                                    return(<option value={prop.data} key={key}>{prop.data}</option>)
                                    }

                                    )
                                }
                            
                            
                            </Select>
                
                        </GridItem> 

                        <GridItem xs={12} sm={12} md={12}>
                            
                            <Select
                                native
                                onChange={this.onChangeNrcNationality.bind(this)}
                                className={classes.gridItem}
                                inputProps={{
                                name: 'tower',
                                id: 'age-native-simple',
                                }}
                                fullWidth={true} 
                            >
                                <option value='' >Select</option>
                                {
                                    nrcData.national.map((prop, key) => {
                                    return(<option value={prop} >{prop}</option>)
                                    }

                                    )
                                }
                            
                            
                            </Select>
                
                        </GridItem> 
                    
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                                labelText="NRC Number"
                                id="nrc_number"
                                inputProps={{
                                        type: 'number',
                                        onChange: this.onChangeNrcNumber
                                    }}
                                formControlProps={{
                                        fullWidth: true
                                    }}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                                labelText="Name"
                                id="name"
                                inputProps={{
                                         type: 'text',
                                         onChange: this.onChangeCustomerName
                                    }}
                                formControlProps={{
                                    fullWidth: true
                                    }}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                            <CustomInput
                                labelText="EligableUnit"
                                id="eligable_unit"
                                inputProps={{
                                         type: 'number',
                                         onChange: this.onChangeEligableUnit
                                    }}
                                formControlProps={{
                                    fullWidth: true
                                    }}
                            />
                        </GridItem>


                     </GridContainer>

                </DialogContent>

                        <DialogActions>

                            <Button onClick={(event) => this.handleCloseDialog()} color="danger">
                                Cancel
                            </Button>

                            <Button  
                                disabled = {!enableAddButton}
                                onClick={() => this.handleAddUser()}
                                color="info">
                                Add
                            </Button>

                        </DialogActions>

                    </Dialog>

                </Card>
            )
        }

        redirctToLogin = () => {

            sessionStorage.removeItem(JWT)
    
            this.props.doLogout(true)

        }

        handleCloseDialog = () => {
            this.props.openAddDialog(false)
        }

        handleOpenAddUserDialog = () => {
            this.props.openAddDialog(true)
        }

        onChangeNrcPreNumber = event =>  {
            this.props.handleNrcPreNumber(event.target.value)
        }
    
        onChangeNrcBody1 = event => {
            this.props.handleNrcBody1(event.target.value)
        }
    
        onChangeNrcBody2 = event => {
            this.props.handleNrcBody2(event.target.value)
        }
    
        onChangeNrcBody3 = event => {
            this.props.handleNrcBody3(event.target.value)
        }
    
        onChangeNrcNationality = event => {
            this.props.handleNrcNationality(event.target.value)
        }
    
        onChangeNrcNumber = event => {
            this.props.handleNrcNumber(event.target.value)
        }

        onChangeCustomerName = event => {
            this.props.handleCustomerName(event.target.value)
        }

        onChangeEligableUnit = event => {
            this.props.handleEligableUnit(event.target.value)
        }

        handleAddUser = () => {

            const { userData, addUser, openAddDialog} = this.props
            
            const { 
                nrcPreNumber,
                nrcBody1,
                nrcBody2,
                nrcBody3,
                nrcNatioanlity,
                nrc,
                name,
                eligable_unit
            } = userData

            openAddDialog(false)

            addUser({name: name, eligable_unit: Number(eligable_unit), nrc: nrcPreNumber+"/"+nrcBody1+""+nrcBody2+""+nrcBody3+""+nrcNatioanlity+""+nrc })
            
        }

}

const styles = theme => ({
    filerContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 20,
        marginTop: 20
    },
    gridItem: {
        margin: 10
    }
})

User.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
    return {
        userData: state.userData
    }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showMenu: (show) => {
            dispatch(
                showMenu(show)
            )
        },
        loadUser: () => {
            dispatch(
                loadUser()
            )
        },
        loadNrcData: () => {
            dispatch(
                loadNrcData()
            )
        },
        openAddDialog: (open) => {
            dispatch(
                openAddDialog(open)
            )
        },
        handleNrcPreNumber: (text) => {
            dispatch(
                handleNrcPreNumber(text)
            )
        },
        handleNrcBody1: (text) => {
            dispatch(
                handleNrcBody1(text)
            )
        },
        handleNrcBody2: (text) => {
            dispatch(
                handleNrcBody2(text)
            )
        },
        handleNrcBody3: (text) => {
            dispatch(
                handleNrcBody3(text)
            )
        },
        handleNrcNationality: (text) => {
            dispatch(
                handleNrcNationality(text)
            )
        },
        handleNrcNumber: (text) => {
            dispatch(
                handleNrcNumber(text)
            )
        },
        handleCustomerName: (text) => {
            dispatch(
                handleCustomerName(text)
            )
        },
        handleEligableUnit: (text) => {
            dispatch(
                handleEligableUnit(text)
            )
        },
        addUser: (props) => {
            dispatch(
                addUser(props)
            )
        },
        doLogout: (logout) => {
            dispatch(
                doLogout(logout)
            )
        }

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(User));