import {
    LOADING,
    UNAUTHORIZED,
    ERROR,
    LOAD_USER,
    LOAD_NRC_DATA,
    OPEN_ADD_USER_DIALOG,
    NRC_PRE_NUMBER,
    NRC_BODY1,
    NRC_BODY2,
    NRC_BODY3,
    NRC_NATIONALITY,
    NRC_NUMBER,
    CUSTOMER_NAME,
    ELIGABLE_UNIT,
    ADD_USER,
    UPDATE_ADD_USER_STATUS,
    LOG_OUT
} from '../actions/types'

const INITIAL_STATE = {
    loading: false,
    error: false,
    unauthorized: false,
    users: [],
    nrcData: {
        nrc_pre_num: [],
        nrc_body: [],
        national: []
    },
    nrcPreNumber: '',
    nrcBody1:'',
    nrcBody2:'',
    nrcBody3:'',
    nrcNatioanlity:'',
    nrc: '',
    openAddUserDialog: false,
    name: '',
    eligable_unit: 0,
    addUserSuccess: false,
    logout: false,

}

export default (state = INITIAL_STATE, action) => {

    // console.log(action.type)
    // console.log(action.payload)

    switch(action.type){

        case LOADING:
            return { ...state, loading: action.payload}

        case LOG_OUT:
            return { ...state, logout: action.payload,  unauthorized: false}
        
        case ERROR:
            return { ...state, error: action.payload, loading: false}
        
        case UNAUTHORIZED:
            return { ...state, unauthorized: action.payload, error: false, loading: false}

        case LOAD_USER:
            return { ...state, users: action.payload, unauthorized: false, error: false, loading: false}
        
        case LOAD_NRC_DATA:
            return  {...state ,
                nrcData : {
                    ...state.nrcData.nrc_pre_num, nrc_pre_num: action.payload.nrc_pre_num,
                    ...state.nrcData.nrc_body, nrc_body: action.payload.nrc_body,
                    ...state.nrcData.national, national: action.payload.national
                }
            }

        case OPEN_ADD_USER_DIALOG: 
            return{ ...state, openAddUserDialog: action.payload}
        
        case NRC_PRE_NUMBER:
            return { ...state, nrcPreNumber: action.payload}

        case NRC_BODY1:
            return { ...state, nrcBody1: action.payload}
        
        case NRC_BODY2:
            return { ...state, nrcBody2: action.payload}
        
        case NRC_BODY3:
            return { ...state, nrcBody3: action.payload}

        case NRC_NATIONALITY:
            return { ...state, nrcNatioanlity: action.payload}
        
        case NRC_NUMBER:
            return { ...state, nrc: action.payload}
        
        case CUSTOMER_NAME:
            return { ...state, name: action.payload }
        
        case ELIGABLE_UNIT:
            return { ...state, eligable_unit: action.payload}
        
        case ADD_USER: 
            return { ...state, addUserSuccess: action.payload, unauthorized: false, error: false, loading: false}
        
        case UPDATE_ADD_USER_STATUS:
            return { ...state, addUserSuccess: action.payload}

        default:
            return state
    }

}