import axios from 'axios'
import {
    LOAD_ENQUIRYS,
    OPEN_ADD_SH_BOOKING_DIALOG,
    NRC,
    TOWER,
    FLOOR,
    UNIT,
    CHOOSE_ROOM,
    UNAUTHORIZED,
    ERROR,
    BOOKING_USER
} from './types'

import { 
    AVAILABLE_ROOM,
    API_ENQIRE,
    API_BOOKING_CONFIRM
} from '../api/EndPoint'

import { 
    JWT,
    RESPONSE_CODE_SUCCESS,
    RESPONSE_CODE_UNAUTHORIZED,
} from '../utils/constants'

export const loadEnquiry =() => dispatch=> {

    axios({
        method: 'get',
        mode: 'no-cors',
        url: API_ENQIRE,
        headers: {
            'token': sessionStorage.getItem(JWT),
            'Content-Type': 'application/json'
            },
        
    }).then( response => {

        const { code, payload  } = response.data;

        if( code === RESPONSE_CODE_SUCCESS ){
            dispatch({
                type: LOAD_ENQUIRYS,
                payload: payload
            })
        }

    }).catch( error => {

        if( error.response.status === RESPONSE_CODE_UNAUTHORIZED ){
            dispatch({ type: UNAUTHORIZED, payload: true})
        }else{
            dispatch({ type: ERROR, payload: true})
        }

    })

}

export const openAddSHBookingDialog = ( open ) => dispatch => {
    dispatch({
        type: OPEN_ADD_SH_BOOKING_DIALOG,
        payload: open
    })
}

export const changeNrc = (text) => dispatch => {
    dispatch({
        type: NRC,
        payload: text
    })
}

export const selectTower = (tower) => dispatch => {
    dispatch({
        type: TOWER,
        payload: tower
    })
}

export const selectFloor = (props) => dispatch => {

    dispatch({
        type: FLOOR,
        payload: props.floor
    })


    loadAvailableRooms(props, dispatch)


}

export const selectUnit = (unit) => dispatch => {
    dispatch({
        type: UNIT,
        payload: unit
    })
}

export const addBookingUser = (user_name) => dispatch => {
    dispatch({
        type: BOOKING_USER,
        payload: user_name
    })
}

export const confirmBooking = (props) => dispatch => {

    axios({
        method: 'post',
        mode: 'no-cors',
        headers: {
        'token': sessionStorage.getItem("jwt"),
        'Content-Type': 'application/json'
        },
        url: API_BOOKING_CONFIRM,
        data: props
    }).then(response => {

        const { code } = response.data;

        if( code === RESPONSE_CODE_SUCCESS ){

            dispatch({
                type: OPEN_ADD_SH_BOOKING_DIALOG,
                payload: false
            })

        }

    }).catch(error => {

    })

}

const loadAvailableRooms = (props, dispatch) => {

    removeEmpty(props)

    axios({
        method: 'get',
        mode: 'no-cors',
        url: AVAILABLE_ROOM,
        headers: {
            'token': sessionStorage.getItem(JWT),
            'Content-Type': 'application/json'
            },
        params: props
    }).then( response => {

        // console.log(response.data)

        const { code, payload  } = response.data;

        if( code === RESPONSE_CODE_SUCCESS ){

            dispatch({
                type: CHOOSE_ROOM,
                payload: payload
            })

        }
       
    }).catch( error => {

        // console.log("-------------------------")
        // console.error(error.response)
        // console.log("-------------------------")

        if( error.response.status === RESPONSE_CODE_UNAUTHORIZED ){
            dispatch({ type: UNAUTHORIZED, payload: true})
        }else{
            dispatch({ type: ERROR, payload: true})
        }

        
    })


}

const removeEmpty = (obj) => {
    Object.keys(obj).forEach((key) => (obj[key] === '') && delete obj[key]);
}

