import axios from 'axios'
import {
    LOAD_ROOM,
    CHANGE_STATUS,
    LOADING,
    ERROR,
    LOG_OUT,
    UNAUTHORIZED,
    FILTER_TOWER,
    FILTER_FLOOR,
    FILTER_TYPE,
    FILTER_STATUS
} from './types'
import {
    AVAILABLE_ROOM,
    UNIT_BLOCK
} from '../api/EndPoint'
import {
    RESPONSE_CODE_SUCCESS,
    RESPONSE_CODE_UNAUTHORIZED
} from '../utils/constants'
import {
    JWT
} from '../utils/constants'

export const loadRoom = (props) => dispatch => {

    const { jwt } = props

  //  console.log("------------- loadRoom --------------")

    axios({
        method: 'get',
        mode: 'no-cors',
        url: AVAILABLE_ROOM,
        headers: {
            'token': jwt,
            'Content-Type': 'application/json'
            },
    }).then( response => {

       //  console.log(response.data)

        const { code, payload  } = response.data;

        if( code === RESPONSE_CODE_SUCCESS ){

            dispatch({
                type: LOAD_ROOM,
                payload: payload
            })

        }
       
    }).catch( error => {

        // console.log("-------------------------")
        // console.error(error.response.status)
        // console.log("-------------------------")

        if( error.response.status === RESPONSE_CODE_UNAUTHORIZED ){
            dispatch({ type: UNAUTHORIZED, payload: true})
        }else{
            dispatch({ type: ERROR, payload: true})
        }
       
    })

}

export const logout = (data) => dispatch => {

    if(data){
       //  sessionStorage.removeItem("JWT")
    }
    
    dispatch({
        type: LOG_OUT,
        payload: data
    })
}

export const filterTower = (props) => dispatch => {

    dispatch({
        type: FILTER_TOWER,
        payload: props.tower
    })

    filterRoom(props, dispatch)


}

export const filterFloor = (props) => dispatch => {

    dispatch({
        type: FILTER_FLOOR,
        payload: props.floor
    })

    filterRoom(props, dispatch)

}

export const filterType = (props) => dispatch => {

    dispatch({
        type: FILTER_TYPE,
        payload: props.layout
    })

    filterRoom(props, dispatch)

}

export const filterStatus = (props) => dispatch => {

    dispatch({
        type: FILTER_STATUS,
        payload: props.availibility
    })

    filterRoom(props, dispatch)
    
}


const filterRoom = (props, dispatch) => {

    removeEmpty(props)

    dispatch({
        type: CHANGE_STATUS,
        payload: false
    })

    dispatch({
        type: LOADING,
        payload: true
    })

    axios({
        method: 'get',
        mode: 'no-cors',
        url: AVAILABLE_ROOM,
        headers: {
            'token': sessionStorage.getItem(JWT),
            'Content-Type': 'application/json'
            },
        params: props
    }).then( response => {

        // console.log(response.data)

        const { code, payload  } = response.data;

        if( code === RESPONSE_CODE_SUCCESS ){

            dispatch({
                type: LOAD_ROOM,
                payload: payload
            })

        }
       
    }).catch( error => {

        // console.log("-------------------------")
        // console.error(error.response)
        // console.log("-------------------------")

        if( error.response.status === RESPONSE_CODE_UNAUTHORIZED ){
            dispatch({ type: UNAUTHORIZED, payload: true})
        }else{
            dispatch({ type: ERROR, payload: true})
        }

        
    })


}


const removeEmpty = (obj) => {
    Object.keys(obj).forEach((key) => (obj[key] === 'all') && delete obj[key]);
}


export const doUnitBlock = (props) => dispatch => {

       // const { tower, floor, room_number, status } = props

        dispatch({
            type: LOADING,
            payload: true
        })

        axios({
            method: 'post',
            headers: {
                'token': sessionStorage.getItem(JWT),
            'Content-Type': 'application/json'
            },
            withCredentials: true,
            mode: 'no-cors',
            url: UNIT_BLOCK,
            data: props
        }).then( response => {

                // console.log(response)

                dispatch({
                    type: CHANGE_STATUS,
                    payload: true
                })

            }
        ).catch( error => {

                // console.log(error.response)

                dispatch({
                    type: ERROR,
                    payload: true
                })

            }
        )
        

}