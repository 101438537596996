import {
    LOAD_ENQUIRYS,
    OPEN_ADD_SH_BOOKING_DIALOG,
    NRC,
    TOWER,
    FLOOR,
    UNIT,
    CHOOSE_ROOM,
    BOOKING_USER
} from '../actions/types'

const INITIAL_STATE = {
    enquirys_list : [],
    open_dialog: false,
    sh_name: '',
    nrc: '',
    tower: '',
    floor: '',
    unit: '',
    type: 'shareholer',
    rooms: []
}

export default ( state = INITIAL_STATE, action ) => {
    
    // console.log('----- enquiry reducer -----')
    // console.log(action.type)
    // console.log(action.payload)
    // console.log('----- enquiry reducer -----')

    switch(action.type){

        case LOAD_ENQUIRYS:
            return { ...state, enquirys_list: action.payload }
        
        case OPEN_ADD_SH_BOOKING_DIALOG:
            return { ...state, open_dialog: action.payload }
        
        case NRC:
            return { ...state, nrc: action.payload}

        case TOWER:
            return { ...state, tower: action.payload}
        
        case FLOOR:
            return { ...state, floor: action.payload}
        
        case UNIT:
            return { ...state, unit: action.payload}
        
        case CHOOSE_ROOM:
            return { ...state, rooms: action.payload}
        
        case BOOKING_USER:
            return {...state, sh_name: action.payload}


        default:
            return state
    }

}