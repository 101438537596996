
import MainDashboard from '../views/dashboard/MainDashboard'
import Booking from '../views/booking/Booking'
import Login from '../views/login/Login'
import User from '../views/users/User'
import ShareHolders from '../views/shareholders/ShareHolders'
import Enquirys from '../views/enquirys/Enquirys'
import {
    LOGIN,
    BOOKING,
    DASHBOARD,
    USERS,
    SHARE_HOLDERS,
    ENQUIRYS
 } from '../utils/constants'

const mainRoutes = [

    {
        path: LOGIN,
        navbarName: "Login",
        component: Login
    },
    {
        path: BOOKING,
        navbarName: "Bookings",
        component: Booking
    },
    {
        path: DASHBOARD,
        navbarName: "Dishboard",
        component: MainDashboard
    },
    {
        path: USERS,
        navbarName: "Users",
        component: User
    },
    {
        path: SHARE_HOLDERS,
        navbarName: "ShareHolers",
        component: ShareHolders
    },
    {
        path: ENQUIRYS,
        navbarName: "Contact",
        component: Enquirys
    },
    { 
        redirect: true, 
        path: "/", 
        to: LOGIN, 
        navbarName: "Redirect" 
    }
    
]

export default mainRoutes