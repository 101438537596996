import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';


class UnauthorizDialog extends Component {


        render() {

            const { open, handleClose } = this.props



            return (
                <div>
                    
                    {/* {
                        !open && (
                           
                            <Redirect from='dashboard' to='login' />
                            
                        )
                    } */}

                    <Dialog
                        disableBackdropClick
                        disableEscapeKeyDown
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Unauthorized"}</DialogTitle>

                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Your session is expired. Please Login again.
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions>

                                <Button onClick={ handleClose } color="primary">
                                    Log out 
                                </Button>

                            </DialogActions>

                    </Dialog>

                </div>
            )

        }

}

const styles = theme => ({
    content: {
        display: 'flex',
        minHeight: '10vh',
        justifyContent: 'center'
    }
})

UnauthorizDialog.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(UnauthorizDialog)