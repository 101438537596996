import {
    SHOW_MENU
} from '../actions/types'

const INITIAL_STATE = {
    show_menu: false
}

export default(state= INITIAL_STATE, action) => {
    switch(action.type){

        case SHOW_MENU:
            return { ...state, show_menu: action.payload }

        default:
            return state
    }
}