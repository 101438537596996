import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from './CustomInput'
import Button from './Button'
import { connect} from 'react-redux'
import {
    openUpdateDepositDialog,
    handleChangeDeposit,
    updateDeposit
 } from '../actions/BookingActions'

class UpdateDepositDialog extends Component {
    
    render() {

        const {
            classes, 
            open,
            handleClose,
            openUpdateDepositDialog,
            deposit
        } = this.props


        return(
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
            >
            <DialogTitle>Update Disposit Amount</DialogTitle>

            <DialogContent>

                <form className={classes.container}>

                    {/* <FormControl className={classes.formControl}>

                        <InputLabel htmlFor="age-native-simple">Tower</InputLabel>
                        
                    </FormControl> */}

                    <FormControl className={classes.formControl}>

                       <CustomInput
                            labelText="DispositAmount"
                            id="dispositamount"
                            value = {deposit}
                            inputProps={{
                                type: 'number',
                                onChange: this.changeDisposit
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}/>

                    </FormControl>

                </form>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => openUpdateDepositDialog({open: false, update_confirmation_no: ''})} color="danger">
                    Cancel
                </Button>
                <Button onClick={() => this.handleUpdateDeposit()} color="info">
                    Update
                </Button>
          </DialogActions>

            </Dialog>
        )
    }

    changeDisposit = (event) => {
        this.props.handleChangeDeposit(event.target.value)
    }

    handleUpdateDeposit= () =>{


        const { updateRoomData, updateDeposit,openUpdateDepositDialog} = this.props

        openUpdateDepositDialog({open: false})

        const {
                updateConfirmationNumber,
                updateDepositAmount
            } = updateRoomData

            updateDeposit({
                confirmation_number: updateConfirmationNumber ,
                deposit: updateDepositAmount
            })

       

    }

}

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing.unit,
      minWidth: 120,
    },
});

UpdateDepositDialog.propsTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {
    return {
        rooms: state.mainDashboard.rooms,
        open: state.bookingData.openUpdateDepositDialog,
        updateRoomData: state.bookingData,
        deposit: state.bookingData.updateDeposit
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        
        openUpdateDepositDialog: (close) => {
            dispatch(
                openUpdateDepositDialog(close)
            )
        },
        handleChangeDeposit: (number) => {
            dispatch(
                handleChangeDeposit(number)
            )
        },
        updateDeposit: (props) => {
            dispatch(
                updateDeposit(props)
            )
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UpdateDepositDialog));