import {
    USER_NAME,
    PASSWORD,
    LOADING,
    LOGIN,
    ERROR,
    UPDATE_LOGIN_STATE
} from './types'
import {
    JWT
} from '../utils/constants'
import {
    AUTHENTICATE
} from '../api/EndPoint'
import axios from 'axios'

export const handleUserName = (text) => dispatch => {
    dispatch({
        type: USER_NAME,
        payload: text
    })
}

export const handlePassword = (text) => dispatch => {
    dispatch({
        type: PASSWORD,
        payload: text
    })
}

export const updateLoginState = (login) => dispatch => {
    dispatch({
        type: UPDATE_LOGIN_STATE,
        payload: login
    })
}


export const doLogin = (props) => dispath => {

    const { user_name, password } = props

    dispath({
        type: LOADING,
        payload: user_name.length > 0 && password.length > 0
    })

    axios({
        method: 'post',
        headers: {
        'Content-Type': 'application/json'
        },
        withCredentials: true,
        mode: 'no-cors',
        url: AUTHENTICATE,
        data: {username: user_name, password: password}
    }).then( response => {

        // console.log('----- login response ------')
        // console.log(response.data.payload)
        // console.log('---------------------------')
        
        sessionStorage.setItem(JWT, response.data.payload.jwt)

        dispath({
            type: LOGIN,
            payload: response.data.payload
        })

    }).catch( error => {

        // console.log('----- login response error------')
        // console.log(error)
        // console.log('--------------------------------')

        dispath({
            type: ERROR,
            payload: true
        })

    })

}