/**
 * Add Share Holder Booking
 */
import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Select from '@material-ui/core/Select'
import Button from '../../components/Button'
import CustomInput from '../../components/CustomInput'
import GridContainer from '../../components/grid/GridContainer'
import GridItem from '../../components/grid/GridItem'
import PropTypes from "prop-types";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

function AddSHBookingDialog({...props}){

    const { classes, open ,sh_name, nrc, tower, floor, unit, handleCloseDialog, onChangeNrc, selectTower, selectFloor, selectUnit, rooms, confirmBooking } = props

    const openButton = !nrc.length > 0 && !tower.length > 0 && !floor.lenght > 0 && !unit.lenght > 0;

    return (
        <Dialog
            open = { open}
            >

            <DialogTitle>
                Add Share Holder Booking
            </DialogTitle>

            <DialogContent>

                <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >
                            <h3 className={classes.label}>
                                Customer Name: {sh_name}
                            </h3>
                    </GridItem>
                    
                    <GridItem xs={12} sm={12} md={12} >
                            <CustomInput
                                labelText="NRC(or)ID"
                                id="nrc"
                                inputProps={{
                                        type: 'text',
                                        onChange: onChangeNrc
                                    }}
                                formControlProps={{
                                    fullWidth: true
                                    }}
                            />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} >

                        <FormControl className={classes.formControl} fullWidth={true}>

                            <InputLabel htmlFor="age-native-simple">Tower</InputLabel>

                            <Select
                                native
                                disabled={!nrc.length > 0}
                                onChange={(event) => selectTower(event.target.value)}
                                input={<Input id="age-native-simple" />}
                                >
                                    <option value="all" >All</option>
                                    <option value="A1">A1</option>
                                    <option value="B2">B2</option>
                                    
                            </Select>
                        </FormControl>

                    </GridItem>
                    
                    <GridItem xs={12} sm={12} md={12} >
                        <FormControl className={classes.formControl} fullWidth = {true}>

                            <InputLabel htmlFor="age-native-simple">Floor</InputLabel>
                                <Select
                                    native
                                    disabled={!tower.length > 0}
                                    onChange = {(event) => selectFloor(event.target.value)}
                                    input={<Input id="age-native-simple" />}
                                    >
                                        <option value="all" >All</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        
                                </Select>
                        </FormControl>  
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} >
                        <FormControl className={classes.formControl} fullWidth = {true}>

                            <InputLabel htmlFor="age-native-simple">Unit</InputLabel>
                                <Select
                                    native
                                     disabled={!floor.length > 0}
                                     onChange = {(event) => selectUnit(event.target.value)}
                                    input={<Input id="age-native-simple" />}
                                    >
                                        <option value="all" >All</option>
                                        
                                        {
                                            rooms
                                            .filter(prop => prop.availibility === 'yes')
                                            .map( (prop, key ) => {

                                            //  if( prop.availibility === 'yes' ) {
                                                    return <option value={prop.room_number} key={key}>{prop.room_number}</option>
                                           //   }
                                       
                                            })
                                        }  
        
                                </Select>
                        </FormControl>  
                    </GridItem>
                
                </GridContainer>

            </DialogContent>

            <DialogActions>

                <Button onClick={(event) => handleCloseDialog(false)} color="danger">
                    Cancel
                </Button>

                <Button  
                    disabled = {openButton}
                    color="info"
                    onClick={(event) => confirmBooking()}>
                        Add
                </Button>

            </DialogActions>


        </Dialog>
    )
}

AddSHBookingDialog.propTypes = {
    classes: PropTypes.object.isRequired
}

const styles = theme => ({
    label: {
        color: "#757575",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: "300",
        fontSize: "14px",
        lineHeight: "1.5em"
    }
})

export default withStyles(styles)(AddSHBookingDialog);