import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import {
    withStyles
} from '@material-ui/core/styles'
import Header from '../components/Header'
import { defaultFont } from '../assets/material-dashboard-react'
import {
    List,
    ListItem,
} from '@material-ui/core'

import {
    BOOKING,
    DASHBOARD,
    USERS,
    ENQUIRYS
} from '../utils/constants'


function CustomMenu ({...props}){

    const {
        classes
    } = props

    return(
        <Header 
            color="info"
            fixed
            leftLinks = {
                <img className={classes.cityloftImage} src={ require('../images/cityloft_colourful.png') } alt="CityLoft"/>
            }
            rightLinks={
                <List className={classes.list}>

                    <ListItem className={classes.listItem}>
                            <NavLink
                                to={BOOKING}
                                className={classes.navLink}
                                color="transparent"
                                >
                                    Bookings
                            </NavLink>
                    </ListItem>

                    <ListItem className={classes.listItem}>
                        <NavLink
                            to={DASHBOARD}
                            className={classes.navLink}
                            color="transparent"
                            >
                                Units
                        </NavLink>
                    </ListItem>

                    <ListItem className={classes.listItem}>
                            <NavLink
                                to={USERS}
                                className={classes.navLink}
                                color="transparent"
                                >
                                    Owners
                            </NavLink>
                    </ListItem>

                    {/* <ListItem className={classes.listItem}>
                            <NavLink
                                to={SHARE_HOLDERS}
                                className={classes.navLink}
                                color="transparent"
                                >
                                    ShareHolders
                            </NavLink>
                    </ListItem> */}

                    <ListItem className={classes.listItem}>
                            <NavLink
                                to={ENQUIRYS}
                                className={classes.navLink}
                                color="transparent"
                                >
                                    Enquirys
                            </NavLink>
                    </ListItem>

                    <ListItem className={classes.listItem}>
                        <NavLink
                            to="login"
                            className={classes.navLink}
                            color="transparent"
                            >
                                Logout
                        </NavLink>
                    </ListItem>

             
                 </List>
            }>
       
        </Header>
    )
}

const styles = theme => ({
    wrapper: {
        position: "relative",
        top: "0",
        height: "100vh"
    },
    footerContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    cityloftImage: {
        width: 150,
        height: 50
    },
    list: {
        ...defaultFont,
        fontSize: "14px",
        margin: 0,
        paddingLeft: "0",
        listStyle: "none",
        paddingTop: "0",
        paddingBottom: "0",
        color: "inherit"
      },
      listItem: {
        float: "left",
        color: "inherit",
        position: "relative",
        display: "block",
        width: "auto",
        margin: "0",
        padding: "0",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          "&:after": {
            width: "calc(100% - 30px)",
            content: '""',
            display: "block",
            height: "1px",
            marginLeft: "15px",
            backgroundColor: "#e5e5e5"
          }
        }
      },
      navLink: {
        color: "inherit",
        position: "relative",
        padding: "0.9375rem",
        fontWeight: "400",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        lineHeight: "20px",
        textDecoration: "none",
        margin: "0px",
        display: "inline-flex",
        "&:hover,&:focus": {
          color: "inherit",
          background: "rgba(200, 200, 200, 0.2)"
        },
        [theme.breakpoints.down("sm")]: {
          width: "calc(100% - 30px)",
          marginLeft: "15px",
          marginBottom: "8px",
          marginTop: "8px",
          textAlign: "left",
          "& > span:first-child": {
            justifyContent: "flex-start"
          }
        }
      },
      navLinkActive: {
        color: "inherit",
        backgroundColor: "rgba(255, 255, 255, 0.1)"
      },

})

CustomMenu.propTypes = {
    classes: PropTypes.object.isRequired
}


export default withStyles(styles)(CustomMenu)

