import {
    USER_NAME,
    PASSWORD,
    LOADING,
    ERROR,
    LOGIN,
    UPDATE_LOGIN_STATE,
} from '../actions/types'

const INITIAL_STATE = {
    user_name:'',
    password: '',
    loading: false,
    login_success: false,
    error: false,
    response_data: null
}

export default (state = INITIAL_STATE, action) => {

    // console.log(action.type)
    // console.log(action.payload)

    switch(action.type){

        case USER_NAME:
            return {...state, user_name: action.payload }

        case PASSWORD:
            return { ...state, password: action.payload }

        case LOADING:
            return { ...state, loading: action.payload , error: false}

        case ERROR: 
            return { ...state, error: action.payload, loading: false }

        case LOGIN:
            return { ...state, response_data: action.payload, login_success: true, loading: false, error: false}

        case UPDATE_LOGIN_STATE:
            return { ...state, login_success: action.payload,user_name:'', password: '' }
        default:
            return state
    }
}