import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    withStyles
} from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
    handleUserName,
    handlePassword,
    doLogin,
    updateLoginState
}   from '../../actions/LoginActions'
import {
    logout
} from '../../actions/MainDashboardAction'
import {
    showMenu
} from '../../actions/MainAction'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import CardBody from '../../components/CardBody'
import GridContainer from '../../components/grid/GridContainer'
import GridItem from '../../components/grid/GridItem'
import CustomInput from '../../components/CustomInput'
import Button from '../../components/Button'
import ProgressDialog from '../../components/ProgressDialog'
import Danger from '../../components/typography/Danger'
import * as Sentry from '@sentry/browser';

Sentry.init({
     dsn: "https://2ea929333d364fcaacced20b2c7229cf@sentry.io/1337445"
});

class Login extends Component {

    componentWillUnmount(){

        this.props.updateLoginState(false)
        this.props.doLogout(false)
       
    }

    componentDidMount(){
       
       this.props.showMenu(false)
       
    }

    componentDidCatch(error, errorInfo){

        Sentry.withScope( scope => {
            
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key])
            })
            
            Sentry.captureException(error)

        })

    }


    render(){
    
            const { classes } = this.props
            const { user_name, password, loading, login_success, error} = this.props.login
           
            return(

                <GridContainer justify="center">

                    <ProgressDialog
                        title="Please Wait..."
                        open={loading}
                    />

                    {
                        login_success && (
                          
                            <Redirect from="login" to="booking" />

                        )
                    }

                    
                    <GridItem xs={12} sm={12} md={4}>
                        <Card profile>

                            <CardHeader color="info">
                                <img 
                                    className={classes.cityloftImage} 
                                    src={ require('../../images/cityloft_colourful.png') } 
                                    alt="CityLoft"/>
                                <h4 className={classes.cardTitleWhite}>Administrator</h4>
                            </CardHeader>

                            <CardBody>

                                {
                                    error && (
                                        <Danger>
                                            Login Failed!
                                        </Danger>
                                    )
                                }

                                <GridContainer direction="column">

                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText="Username"
                                            id="username"
                                            value={user_name}
                                            onChange={(event)=> this.changeUserName(event.target.value)}
                                            inputProps={{
                                                type: 'email',
                                                value: user_name,
                                                onChange: this.changeUserName
                                            }}
                                            formControlProps={{
                                            fullWidth: true
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText="Password"
                                            id="password"
                                            formControlProps={{
                                            fullWidth: true,
                                            }}
                                            value={password}
                                            inputProps={{
                                                type: 'password',
                                                value: password,
                                                onChange: this.changePassword
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem>
                                        <Button color="info" disabled={!this.getButtonState()} onClick={() => this.handleLogin()}>
                                            Login
                                        </Button>
                                    </GridItem>


                                </GridContainer>

                            </CardBody>

                            {/* <NavLink to="dashboard" activeClassName="active">
                                <Button>
                                    go dashboard
                                </Button>
                            </NavLink> */}

                        </Card> 
                    </GridItem>

                </GridContainer>
               
            )
    }

    changeUserName = (event) => {
        
        this.props.handleUserName(event.target.value)

    }

    changePassword = (event) => {
        this.props.handlePassword(event.target.value)
    }

    getButtonState(){
        const { user_name, password} = this.props.login
        
        return user_name.length > 0 && password.length > 0
    }

    handleLogin(){
        const { user_name, password} = this.props.login
        this.props.handleLogin({user_name, password})
    }

}

const styles = theme => ({
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    cityloftImage: {
        width: 120,
        heigh: 10
    },
    

})

Login.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
    return {
        login: state.login
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

        handleUserName: (text) => {
            dispatch(handleUserName(text))
        },
        handlePassword: (text) => {
            dispatch(handlePassword(text))
        },
        handleLogin: ({user_name, password}) => {
            dispatch(doLogin({user_name, password}))
        },
        doLogout: (data) => {
            dispatch(
                logout(data)
            )
       },
       updateLoginState: (login) => {
           dispatch(
                updateLoginState(login)
           )
       },
       showMenu: (show) => {
           dispatch(
               showMenu(show)
           )
       }

    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles)(Login))