/**
 * Response Code
 */
export const RESPONSE_CODE_UNAUTHORIZED = 403
export const RESPONSE_CODE_SUCCESS = 200
export const RESPONSE_CODE_GENERATE_URL_SUCCESS = 201

/**
 * token
 */
export const TOKEN = "mickymouse"

/**
 * session
 */
export const JWT = 'jwt'

/**
 * Route Name
 */
export const LOGIN = "/admin/login"
export const BOOKING = "/admin/booking"
export const DASHBOARD = "/admin/dashboard"
export const USERS = "/admin/user"
export const SHARE_HOLDERS = "/admin/shareholders"
export const ENQUIRYS = '/admin/enquirys'