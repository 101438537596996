import {
    LOAD_ROOM,
    CHANGE_STATUS,
    LOADING,
    ERROR,
    UNAUTHORIZED,
    LOG_OUT,
    FILTER_TOWER,
    FILTER_FLOOR,
    FILTER_TYPE,
    FILTER_STATUS
} from '../actions/types'

const INITIAL_STATE = {
    rooms: [],
    filterTower:'all',
    filterFloor:'all',
    filterType: 'all',
    filterStatus:'all',
    unauthorize: false,
    logout: false,
    changeStatusSuccess: false,
    error: false,
    loading: false 
}

export default (state= INITIAL_STATE, action) => {

    switch(action.type){

        case LOAD_ROOM:
            return {...state, rooms: action.payload, error: false, loading: false, changeStatusSuccess: false }

        case FILTER_TOWER:
            return {...state, filterTower: action.payload}

        case FILTER_FLOOR:
            return {...state, filterFloor: action.payload}
        
        case FILTER_TYPE:
            return {...state, filterType: action.payload}
        
        case FILTER_STATUS:
            return {...state, filterStatus: action.payload}

        case LOADING:
            return {...state, loading: action.payload, error: false}
        
        case CHANGE_STATUS:
            return {...state, changeStatusSuccess: action.payload, error: false}
        
        case ERROR:
            return { ...state, error: action.payload, loading: false}
        
        case LOG_OUT: 
            return { ...state, logout: action.payload, unauthorize: false}
        
        case UNAUTHORIZED:
            return { ...state, unauthorize: action.payload, loading: false}
        
        default:
            return state
    }

}