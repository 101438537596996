import React,{Component} from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    CircularProgress,
    CssBaseline
} from '@material-ui/core';

class ProgressDialog extends Component {

    render(){

        const { classes, open, title} = this.props

        return(
            <div>
                <CssBaseline/>

                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open = {open}>

                    <DialogTitle>{title}</DialogTitle>

                    <DialogContent>
                        <div className={classes.content}>
                            <CircularProgress className={classes.progress} size={50} color='secondary' />
                        </div>
                    </DialogContent>

                </Dialog>

            </div>
        )
    }



}


const styles = theme => ({
    content: {
        display: 'flex',
        minHeight: '10vh',
        justifyContent: 'center'
    }
})

ProgressDialog.propTypes = {
    classes: PropTypes.object.isRequired
}


export default withStyles(styles)(ProgressDialog)