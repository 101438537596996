import React, {Component} from "react";
import { Route, Switch, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
    withStyles
} from '@material-ui/core/styles'
import Menu from '../components/Menu'
import mainRoutes from '../routes/MainRoutes'
import {
    JWT
} from '../utils/constants'
import {
    connect
} from 'react-redux'
import * as Sentry from '@sentry/browser';

Sentry.init({
     dsn: "https://2ea929333d364fcaacced20b2c7229cf@sentry.io/1337445"
    });

const switchRoutes = (
    <Switch>
            {
                mainRoutes.map((prop, key) => {

                    if (prop.redirect){
                        return <Redirect from={prop.path} to={prop.to} key={key} />;
                    }
                    
                    return <Route path={prop.path} component={prop.component} key={key} />;
                })
            }
    </Switch>
);

class Main extends Component{

    componentWillMount(){
        
        if(this.props.show_menu){
            sessionStorage.removeItem(JWT)
        }

    }

    componentDidCatch(error, errorInfo){

        Sentry.withScope( scope => {
                
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key])
            })
            
            Sentry.captureException(error)

        })

    }
    
    render(){

        const { classes, show_menu } = this.props

        return(
            <div className={classes.wrapper}>
                {
                    show_menu && (
                        <Menu/>
                    )
                }
                
                <div className={classes.routeHolder}>
                    { switchRoutes }
                </div>
            
            <footer>
                <div className={classes.footerContainer}>
                    <h3 className={classes.footerText}>
                        If you encounter any issue, please contact <a href='mailto:digital@yomastrategic.com'>digital@yomastrategic.com.</a>
                    </h3>
                </div>
                
            </footer>
              
            </div>
            
        )
       
    }

}

const styles = theme => ({
    wrapper: {
        position: "relative",
        top: "0",
        height: "100vh"
    },
    routeHolder: {
        paddingTop: "32px"
    },
    footerContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    footerText: {
        color: "#757575",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: "300",
        fontSize: "14px",
        lineHeight: "1.5em"
    }
    
})

Main.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

    // console.log('--------> @Main@ <----------')
    // console.log(state)
    // console.log('--------> Main <----------')

    return {
        show_menu: state.main.show_menu
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Main))