import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    withStyles
} from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Card from '../../components/Card'
import CardBody from '../../components/CardBody'
import CustomInput from '../../components/CustomInput'
import Button from '../../components/Button'
import BookingTable from './BookingTable';
import {
    Search,
} from "@material-ui/icons";

import EditDialog from '../../components/EditDialog'
import UpdateDepositDialog from '../../components/UpdateDepositDialog'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { 
    loadBooking,
    updateStatus,
    changeConfirmationNumber,
    openEditDailog,
    closeErrorDialog,
    openUpdateDepositDialog
} from '../../actions/BookingActions'
import {
    showMenu
} from '../../actions/MainAction'
import {
    JWT
} from '../../utils/constants'
import { 
    loadRoom,
} from '../../actions/MainDashboardAction'

import * as Sentry from '@sentry/browser';


Sentry.init({
     dsn: "https://2ea929333d364fcaacced20b2c7229cf@sentry.io/1337445"
    });

class Booking extends Component {

        componentDidMount(){
         
            this.props.getBookings()

        //    this.props.loadAvailableRoom()

            this.props.showMenu(true)

        }

        componentDidCatch(error, errorInfo){

            Sentry.withScope( scope => {
                
                Object.keys(errorInfo).forEach(key => {
                    scope.setExtra(key, errorInfo[key])
                })
                
                Sentry.captureException(error)

            })

        }

        render(){

            const { classes } = this.props

            // console.log('----- booking -----')
            // console.log(this.props)
            // console.log('----------------')

            // const { bookings, updateBookingSuccess, openEditDialog } = this.props.booking_data
            const { bookings, updateBookingSuccess, updateDepositSuccess, updateRoomSuccess, error, errorMessage } = this.props.booking_data

            if( updateBookingSuccess || updateDepositSuccess || updateRoomSuccess) {
               // console.log(updateBookingSuccess)
                this.props.getBookings()
            }

            return(
                <div>
                
                <Card>

                    <div className= {classes.filerContainer}>


                        <div className={classes.filter}>

                            <CustomInput
                                labelText="Confirmation Number"
                                id="username"
                                inputProps={{
                                    onChange: this.handleChangeComfirmationNumber
                                }}
                                formControlProps={{
                                fullWidth: false
                            }}/>

                            <Button 
                                justIcon  
                                color="info"
                                onClick= { (event) => this.handleSearchBooking()}>
                                <Search/>
                            </Button>

                        </div>

                    </div>
                   

                    <CardBody>
                        <BookingTable
                            tableHeaderColor="warning"
                            tableHead={["Confirmation No", "Name", "NRC", "User Type", "Deposit","UpdateDeposit","Tower","Floor", "Room No", "Status", "UpdateBooking"]}
                            tableData={bookings}
                            onItemClick = {this.handleEditBooking}
                            chnageStatus = {this.handleStatusChange}
                            updateDeposit= {this.handleOpenUpdateDepositDialog}
                        />
                    </CardBody>

                    {
                        bookings.length === 0 && (
                            <div className= {classes.messageContainer}>
                                <h3 className={classes.message}>
                                    No Bookings
                                </h3> 
                            </div>
                        )
                    }
                    
                    <EditDialog/>

                    <Dialog open={error} >

                        <DialogTitle 
                        id="alert-dialog-title"
                        >
                        Information
                        </DialogTitle>

                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                { errorMessage }
                            </DialogContentText>
                        </DialogContent>


                        <DialogActions>
                            <Button onClick={(event) => this.handleCloseDialog()} color="info">
                                OK
                            </Button>
                        </DialogActions>

                    </Dialog>

                    <UpdateDepositDialog/>

                </Card>

             </div>
            )
        }

        handleCloseDialog = () => {

            this.props.closeErrorDialog(false)

        }

        handleChangeComfirmationNumber = (event) => {

          //  console.log(event.target.value)

            this.props.changeConfirmationNumber(event.target.value)

        }

        handleEditBooking = (prop) => {
            // console.log('----- onItemClick ------')
            // console.log(prop)

            this.props.openDialog({open: true, update_confirmation_no: prop.confirmation_number})
        }

        handleOpenUpdateDepositDialog = (prop) => {
                
            this.props.openUpdateDepositDialog({open: true, update_confirmation_no: prop.confirmation_number})

        }
    
        handleStatusChange = (props) => event =>{
    
            // console.log(props)
            // console.log(event.target.value)

            const { confirmation_number } = props

            this.props.updateBookingStatus({ confirmation_number: confirmation_number, status: event.target.value})

        }

        handleSearchBooking(){

            const { confirmationNumber } =  this.props.booking_data

            // console.log("-------- confirmation ------ ")
            // console.log(confirmationNumber)
            // console.log("-------- confirmation ------ ")
           
            this.props.getBookings({confirmation_number: confirmationNumber })

            
        }

}

const styles = theme => ({
    filerContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 20
    },
   
    messageContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    message: {
        color: "#757575",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
    }
})

Booking.propsTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
   
    return {
        booking_data: state.bookingData,
        avaiblable_rooms: state.mainDashboard.rooms
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showMenu: (show) => {
            dispatch(
                showMenu(show)
            )
        },
        getBookings: (props) => {
            dispatch(
                loadBooking(props)
            )
        },
        updateBookingStatus: (props) => {
            dispatch(
                updateStatus(props)
            )
        },
        changeConfirmationNumber: (text) => {
            dispatch(
                changeConfirmationNumber(text)
            )
        },
        openDialog: ( open ) => {
            dispatch(
                openEditDailog(open)
            )
        },
        loadAvailableRoom: () => {
            dispatch(
                loadRoom({ jwt: sessionStorage.getItem(JWT) })
            )
        },
        closeErrorDialog: (close) => {
            dispatch(
                closeErrorDialog(close)
            )
        },
        openUpdateDepositDialog: (open) => {
            dispatch(
                openUpdateDepositDialog(open)
            )
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles)(Booking))