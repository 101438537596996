import {
    UNAUTHORIZED,
    LOADING,
    ERROR,
    LOAD_BOOKING,
    UPDATE_RELOAD_STATUS,
    CHANGE_CONFIRMATION_NUMBER,
    OPEN_EDIT_DIALOG,
    SELECT_TOWER,
    SELECT_FLOOR,
    SELECT_ROOM_NO,
    UPDATE_ROOM,
    CLOSE_ERROR_DIALOG,
    OPEN_UPDATE_DEPOSIT_DIALOG,
    CHANGE_DEPOSIT,
    UPDATE_DEPOSIT,
    AVAILABLE_ROOMS
} from '../actions/types'

const INITIAL_STATE = {
    loading: false,
    unauthorize: false,
    error: false,
    errorMessage: '',
    bookings: [],
    rooms: [],
    updateBookingSuccess: false,
    confirmationNumber: '',
    openEditDialog: false,
    updateConfirmationNumber: '',
    updateTower:'',
    updateFloor:0,
    updateRoomNo:'',
    updateRoomSuccess: false,
    openUpdateDepositDialog: false,
    updateDepositAmount: 0,
    updateDepositSuccess: false,
}

export default (state = INITIAL_STATE, action) => {

    // console.log(action.type)
    // console.log(action.payload)

    switch(action.type) {

        case LOADING:
                return { ...state, loading: action.payload}

        case LOAD_BOOKING:
            return { ...state, 
                    bookings: action.payload, 
                    loading: false, 
                    unauthorize: false, 
                    error: false, 
                    updateBookingSuccess: false,
                    updateDepositSuccess: false, 
                    updateRoomSuccess: false }

        case AVAILABLE_ROOMS:
            return { ...state, rooms: action.payload }
        
        case UNAUTHORIZED:
            return { ...state, unauthorize: true, loading: false, error: false, updateBookingSuccess: false }

        case ERROR:
            return { ...state, 
                    unauthorize: false, 
                    loading: false,
                    error: action.payload.error, 
                    errorMessage: action.payload.message,
                    updateBookingSuccess: false }
        
        case UPDATE_RELOAD_STATUS:
            return { ...state,
                updateBookingSuccess: action.payload,
                updateDepositSuccess: action.payload, 
                updateRoomSuccess: action.payload, 
                loading: false, 
                error: false }
        
        case CHANGE_CONFIRMATION_NUMBER:
            return { ...state, confirmationNumber: action.payload}

        case OPEN_EDIT_DIALOG:
            return { ...state, 
                openEditDialog: action.payload.open, 
                updateConfirmationNumber: action.payload.update_confirmation_no }

        case SELECT_TOWER:
            return { ...state, updateTower: action.payload}

        case SELECT_FLOOR:
            return { ...state, updateFloor: action.payload}

        case SELECT_ROOM_NO:
            return { ...state, updateRoomNo: action.payload}
        
        case UPDATE_ROOM:
            return { ...state, updateRoomSuccess: action.payload, loading: false, error: false}
        
        case CLOSE_ERROR_DIALOG:
            return { ...state, error: false, errorMessage: ''}
        
        case OPEN_UPDATE_DEPOSIT_DIALOG: 
            return { ...state, 
                openUpdateDepositDialog: action.payload.open, 
                updateConfirmationNumber: action.payload.update_confirmation_no }
        
        case CHANGE_DEPOSIT:
            return { ...state, updateDepositAmount: action.payload}
        
        case UPDATE_DEPOSIT:
            return { ...state, updateDepositSuccess: true, loading: false, unauthorize: false, error: false, }
        
        default:
            return state
    }

}