import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import Select from "@material-ui/core/Select"
// import Danger from "./typography/Danger"

// core components
import tableStyle from "../../assets/components/tableStyle";


function UserTable({ ...props }) {

  const { classes, tableHead, tableData, tableHeaderColor, onItemClick  } = props;

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
              // console.log(prop.updated_at)
            return (
              <TableRow key={key} onClick={ (event) => onItemClick(key)}>

                <TableCell>
                  {prop.name}
                </TableCell>

                <TableCell>
                  { prop.nrc }
                </TableCell>

                <TableCell>
                { prop.booking_number }
                </TableCell>
                
                <TableCell>
                { prop.eligable_unit }
                </TableCell>
                
                <TableCell>
                { prop.booked_unit }
                </TableCell>
    

              </TableRow>

            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}


UserTable.defaultProps = {
  tableHeaderColor: "gray"
};

UserTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object)
};
//tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
export default withStyles(tableStyle)(UserTable);
