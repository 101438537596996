import React from 'react';
import ReactDOM from 'react-dom';
import {
    createBrowserHistory 
} from 'history'

import * as serviceWorker from './serviceWorker';
import indexRoutes from './routes/index'
import {Router, Route, Switch } from 'react-router-dom'
import configStore from './stores'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser';

const history = createBrowserHistory();

Sentry.init({ dsn: 'https://2ea929333d364fcaacced20b2c7229cf@sentry.io/1337445' });

const indexRoute = (
    <Switch>
       
        {
            indexRoutes.map((prop, key) => { 
                return <Route path={prop.path} component={prop.component} key={key} />
            })
        }

    </Switch>
)


ReactDOM.render(
    <Provider store={configStore()}>
        <Router basename={'/admin'} history={history}>
            
            { indexRoute }
           
         </Router>
    </Provider>
, document.getElementById('root'));



serviceWorker.unregister();
