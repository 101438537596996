import { 
    combineReducers
} from 'redux'
import MainReducer from './MainReducer'
import LoginReducer from './LoginReducer'
import MainDashboardReducer from './MainDashboardReducer'
import BookingReducer from './BookingReducer'
import UserReducer from './UserReducer'
import EnquiryReducer from './EnquiryReducer'


export default combineReducers({
    main: MainReducer,
    login: LoginReducer,
    mainDashboard: MainDashboardReducer,
    bookingData: BookingReducer,
    userData: UserReducer,
    enquirys: EnquiryReducer
})