import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Card from '../../components/Card'
import CardBody from '../../components/CardBody'
import {
    showMenu
} from '../../actions/MainAction'
import { 
    loadNrcData
} from '../../actions/UserActions'
import {
    loadEnquiry,
    openAddSHBookingDialog,
    changeNrc,
    selectTower,
    selectFloor,
    selectUnit,
    confirmBooking,
    addBookingUser
} from '../../actions/EnquiryActions'

import EnquirysTable from './EnquirysTable'
import AddSHBookingDialog from './AddSHBookingDialog'



class Enquirys extends Component {

    componentDidMount(){
        this.props.loadEnquiry()
        this.props.showMenu(true)
        this.props.loadNrcData()
        
    }

    render(){

        // console.log("------- enquiry prop --------")
        // console.log(this.props)
        // console.log("------- enquiry prop --------")

        const { enquirys } = this.props

        return (
            <div>
                <Card>

                    <CardBody>
                        <EnquirysTable
                            tableHeaderColor="warning"
                            tableHead={["Name", "Phone Number", "Budget", "Unit Type", "Age", "Gender", "Marital Status", "Message",  "Received Date", "Add Booking", ]}
                            tableData = {enquirys.enquirys_list}
                            addBooking ={this.handleAddBooking}
                        />
                    </CardBody>

                    <AddSHBookingDialog 
                        sh_name={enquirys.sh_name}
                        nrc = {enquirys.nrc}
                        tower = {enquirys.tower}
                        floor = {enquirys.floor}
                        unit = {enquirys.unit}
                        open={ enquirys.open_dialog }
                        handleCloseDialog = {this.handleCloseDialog}
                        onChangeNrc={this.handleOnChangeNrc}
                        selectTower={this.handleSelectTower}
                        selectFloor={this.handleSelectFloor}
                        selectUnit={this.handleSelectUnit}
                        rooms = {enquirys.rooms}
                        confirmBooking={this.handleConfirmBooking}
                    />

                </Card> 
            </div>
        )
    }

    handleAddBooking = (prop) => {
        // console.log("------ bandle booking --------")
        // console.log(prop)
        // console.log("------ bandle booking --------")

        const { name } = prop
        
        this.props.addBookingUser(name)

        this.props.openAddBookingDialog(true)
    }

    handleConfirmBooking = () => {

        const { 
            nrc,
            tower,
            floor,
            unit,
            sh_name
        } = this.props.enquirys

        this.props.confirmBooking({name: sh_name, nrc: nrc, tower: tower, floor: parseInt(floor), room_number: parseInt(unit), type: "shareholder"})

    }

    handleCloseDialog = ( open ) => {
        this.props.openAddBookingDialog(open)
    }

    handleOnChangeNrc = event => {
       // console.log(event.target.value)
        this.props.changeNrc(event.target.value)
    }

    handleSelectTower = (tower) => {
      //  console.log(tower)
        this.props.selectTower(tower)
    }

    handleSelectFloor = (floor) => {

        // console.log(this.props.enquirys.tower)
        // console.log(floor)

        const tower = this.props.enquirys.tower

        this.props.selectFloor({ tower, floor})
    }

    handleSelectUnit = (unit) => {
        // console.log(unit)
        this.props.selectUnit(unit)
    }

}

const styles = theme => ({

})

Enquirys.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

    // console.log('---- list -----')
    // console.log(state)
   
    return {
        enquirys: state.enquirys,
        nrc_data: state.userData.nrcData
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {

    return {
        showMenu: (show) => {
            dispatch(
                showMenu(show)
            )
        },
        loadNrcData: () => {
            dispatch(loadNrcData())
        },
        loadEnquiry: () => {
            dispatch(
                loadEnquiry()
            )
        },
        openAddBookingDialog: (open) => {
            dispatch(
                openAddSHBookingDialog(open)
            )
        },
        changeNrc: (text) =>{
            dispatch(
                changeNrc(text)
            )
        },
        selectTower: (tower) => {
            dispatch(
                selectTower(tower)
            )
        },
        selectFloor: (props) => {
            dispatch(
                selectFloor(props)
            )
        },
        selectUnit: (unit) => {
            dispatch(
                selectUnit(unit)
            )
        },
        confirmBooking: (props) => {
            dispatch(
                confirmBooking(props)
            )
        },
        addBookingUser: (user_name) => {
            dispatch(
                addBookingUser(user_name)
            )
        }

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Enquirys))