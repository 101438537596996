import {
    SHOW_MENU
} from './types'

export const showMenu = (show_menu) => dispatch => {

    dispatch({
        type: SHOW_MENU,
        payload: show_menu
    })

}