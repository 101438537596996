export const BASE_URL = "https://clus.harpy.yomastrategic.net"
//export const BASE_URL = "http://10.0.121.142:5000";
//export const BASE_URL = "https://clus.phoenix.yomastrategic.net"

export const AUTHENTICATE =  `${BASE_URL}/users/admin/login`
export const AVAILABLE_ROOM = `${BASE_URL}/units/all`
export const SUBMIT_UNIT = `${BASE_URL}/bookings/confirm`
export const UNIT_BLOCK = `${BASE_URL}/units/block`
export const GET_BOOKINGS = `${BASE_URL}/bookings/list`
export const UPDATE_BOOKING = `${BASE_URL}/bookings/update/status`
export const UPDATE_ROOM = `${BASE_URL}/bookings/update/room`
export const API_UPDATE_DEPOIST = `${BASE_URL}/bookings/update/deposit`
export const API_USER_LIST = `${BASE_URL}/users/list`
export const API_CREATE_USER = `${BASE_URL}/users/add`
export const API_ENQIRE = `${BASE_URL}/enquiries/list`
export const API_BOOKING_CONFIRM = `${BASE_URL}/bookings/confirm`