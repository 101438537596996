import axios from 'axios'
import {
    LOAD_USER,
    UNAUTHORIZED,
    ERROR,
    LOAD_NRC_DATA,
    OPEN_ADD_USER_DIALOG,
    NRC_PRE_NUMBER,
    NRC_BODY1,
    NRC_BODY2,
    NRC_BODY3,
    NRC_NATIONALITY,
    NRC_NUMBER,
    CUSTOMER_NAME,
    ELIGABLE_UNIT,
    ADD_USER,
    UPDATE_ADD_USER_STATUS,
    LOG_OUT
} from './types'
import {
    API_USER_LIST,
    API_CREATE_USER
} from '../api/EndPoint'
import { 
    JWT,
    RESPONSE_CODE_SUCCESS,
    RESPONSE_CODE_UNAUTHORIZED
} from '../utils/constants'

import nrc_data from '../utils/NrcData'


export const loadUser = () => dispatch => {

        dispatch({
            type: UPDATE_ADD_USER_STATUS,
            payload: false
        })

        axios({
            method: 'get',
            headers: {
                'token': sessionStorage.getItem(JWT),
            'Content-Type': 'application/json'
            },
            withCredentials: true,
            mode: 'no-cors',
            url: API_USER_LIST,
           
        }).then( response => {
    
           // console.log(response)
    
            if(response.data.code === RESPONSE_CODE_SUCCESS){
    
                dispatch({
                    type: LOAD_USER,
                    payload: response.data.payload
                })
            }
                
    
        }).catch( error => {
    
            if( error.response.status === RESPONSE_CODE_UNAUTHORIZED ){
                dispatch({ type: UNAUTHORIZED, payload: true})
            }else{
                dispatch({ type: ERROR, payload: true})
            }
    
    })
        
}

export const doLogout = (logout) => dispatch => {
    dispatch({
        type: LOG_OUT,
        payload: logout
    })
}

export const loadNrcData = () => dispatch => {
    dispatch({
        type: LOAD_NRC_DATA,
        payload: nrc_data
    })
}

export const openAddDialog = (open) => dispatch => {
    dispatch({
        type: OPEN_ADD_USER_DIALOG,
        payload: open
    })
}

export const handleNrcPreNumber = ( text ) => dispatch => {
    dispatch({
        type: NRC_PRE_NUMBER,
        payload: text
    })
}

export const handleNrcBody1 = ( text ) => dispatch => {
    dispatch({
        type: NRC_BODY1,
        payload: text
    })
}

export const handleNrcBody2 = ( text ) => dispatch => {
    dispatch({
        type: NRC_BODY2,
        payload: text
    })
}

export const handleNrcBody3 = ( text ) => dispatch => {
    dispatch({
        type: NRC_BODY3,
        payload: text
    })
}

export const handleNrcNationality = ( text ) => dispatch => {
    dispatch({
        type: NRC_NATIONALITY,
        payload: text
    })
}

export const handleNrcNumber = ( text ) => dispatch => {
    dispatch({
        type: NRC_NUMBER,
        payload: text
    })
}

export const handleCustomerName = ( text ) => dispatch => {
    dispatch({
        type: CUSTOMER_NAME,
        payload: text
    })
}

export const handleEligableUnit = (text) => dispatch => {
    dispatch({
        type: ELIGABLE_UNIT,
        payload: Number(text)
    })
}

export const addUser = (props) => dispatch => {

    axios({
        method: 'post',
        headers: {
            'token': sessionStorage.getItem(JWT),
        'Content-Type': 'application/json'
        },
        withCredentials: true,
        mode: 'no-cors',
        url: API_CREATE_USER,
        data: props
    }).then( response => {

        if(response.data.code === RESPONSE_CODE_SUCCESS){

            dispatch({
                type: ADD_USER,
                payload: true
            })

        }

    }).catch(error => {

        if( error.response.status === RESPONSE_CODE_UNAUTHORIZED ){
            dispatch({ type: UNAUTHORIZED, payload: true})
        }else{
            dispatch({ type: ERROR, payload: true})
        }

    })
}
