import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Select from "@material-ui/core/Select"
// import Danger from "./typography/Danger"
import Info from "./typography/Info"

// core components
import tableStyle from "../assets/components/tableStyle";

function convertdate(date) {
  var d = new Date(date);
  return d.toLocaleString();
}

function CustomTable({ ...props }) {

  const { classes, tableHead, tableData, tableHeaderColor, onItemClick, chnageStatus  } = props;

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
              // console.log(prop.updated_at)
            return (
              <TableRow key={key} onClick={ (event) => onItemClick(key)}>

                {/* {
                  prop.map((prop, key) => {

                    return (
                      <TableCell className={classes.tableCell} key={key}>
                        {prop}
                      </TableCell>
                    );

                  })
                } */}

                <TableCell>
                  {prop.tower}
                </TableCell>

                <TableCell>
                  { prop.floor }
                </TableCell>

                <TableCell>
                { prop.layout }
                </TableCell>
                
                <TableCell>
                { prop.room_number }
                </TableCell>
                
                <TableCell>
                  { 
                    prop.updated_at === undefined ? (
                      <div>---</div>
                    ):(
                      //prop.updated_at
                        convertdate(prop.updated_at) 
                    )
                  }
                </TableCell>

                <TableCell>
                  {
                    prop.updated_by === undefined ? (
                      <div>---</div>
                    ):(
                      prop.updated_by
                    )
                  }
                </TableCell>

                <TableCell>

                  {
                    prop.availibility === 'no' ? (
                      
                      // <Danger> Paid </Danger>
                      <Info> Paid </Info>

                    ):(
                      <Select
                            native
                            value={prop.availibility}
                            onChange={chnageStatus(prop)}
                            name="status"
                            inputProps={{
                              id: 'age-native-required',
                          }}>
                            <option value=""/>
                            <option value="yes">Available</option>
                            <option value="booked">Booked</option>
                            <option value="no">Paid</option>
                        </Select>
                    )
                  }
                  
                </TableCell>

              </TableRow>

            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}


CustomTable.defaultProps = {
  tableHeaderColor: "gray"
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object)
};
//tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
export default withStyles(tableStyle)(CustomTable);
