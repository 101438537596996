import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

class ShareHolder extends Component {

    render(){
        return (
            <h1>
                ShareHolder
            </h1>
        )
    }

}

const styles = theme => ({

})

ShareHolder.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ShareHolder)