import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton"
import { 
  Add
} from '@material-ui/icons'
//import Select from "@material-ui/core/Select"
// import Danger from "../../components/typography/Danger"
//import Info  from "../../components/typography/Info"

// core components
import tableStyle from "../../assets/components/tableStyle";

function convertdate(date) {
  var d = new Date(date);
  return d.toLocaleString();
}

function CustomTable({ ...props }) {

   const { classes, tableHead, tableData, tableHeaderColor, addBooking  } = props;
  //const { classes, tableHead, tableData, tableHeaderColor, chnageStatus  } = props;

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop, key) => {
              // console.log(prop.updated_at)
            return (
              <TableRow key={key} >

                <TableCell className={classes.tableCell}>
                  {prop.name}
                </TableCell>

                <TableCell className={classes.tableCell}>
                { prop.phone_number }
                </TableCell>

                <TableCell className={classes.tableCell}>
                  { prop.budget }
                </TableCell>

                <TableCell className={classes.tableCell}>
                  { prop.unit_type }
                </TableCell>

                <TableCell className={classes.tableCell}>
                  { prop.age }
                </TableCell>

                <TableCell className={classes.tableCell}>
                  { prop.gender }
                </TableCell>

                <TableCell className={classes.tableCell}>
                  { prop.marital_status }
                </TableCell>

                <TableCell className={classes.tableCell}>
                  { prop.message }
                </TableCell>

                <TableCell className={classes.tableCell}>
                { convertdate(prop.timestamp) }
                </TableCell>

                <TableCell>
                  <IconButton onClick={ (event) => addBooking(prop)} disabled= {prop.status === 'completed'}>
                    <Add/>
                  </IconButton>
                </TableCell>

              </TableRow>

            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}


CustomTable.defaultProps = {
  tableHeaderColor: "gray"
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object)
};
//tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
export default withStyles(tableStyle)(CustomTable);
