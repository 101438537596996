import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    withStyles
} from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Card from '../../components/Card'
import CardBody from '../../components/CardBody'
import GridContainer from '../../components/grid/GridContainer'
import GridItem from '../../components/grid/GridItem'
import Table from '../../components/Table'
import ProgressDialog from '../../components/ProgressDialog'
import UnauthorizDialog from '../../components/UnauthorizeDialog'
import { Redirect } from 'react-router-dom'
import { 
    FormControl,
    InputLabel,
    Select
}from '@material-ui/core'
import { 
    loadRoom,
    filterTower,
    filterFloor,
    filterType,
    filterStatus,
    doUnitBlock,
    logout
} from '../../actions/MainDashboardAction'
import {
    showMenu
} from '../../actions/MainAction'
import {
    JWT
} from '../../utils/constants'
import * as Sentry from '@sentry/browser';

Sentry.init({
     dsn: "https://2ea929333d364fcaacced20b2c7229cf@sentry.io/1337445"
});


class MainDashboard extends Component {

    componentWillMount(){
        this.props.showMenu(true)

    }

    componentDidMount(){

        const { loadRoom } = this.props

        loadRoom( { jwt: sessionStorage.getItem(JWT) })
        
    }

    componentDidCatch(error, errorInfo){

        Sentry.withScope( scope => {
            
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key])
            })
            
            Sentry.captureException(error)

        })

    }



    render(){

    // console.log(' --- mapmapStateToProps @MainDashboard---')
    // console.log(this.props)
    // console.log(' --- mapmapStateToProps @MainDashboard---')

        const { classes } = this.props
        const { 
                rooms, 
                changeStatusSuccess, 
                filterTower,
                filterFloor, 
                filterType,
                filterStatus,
                loading,
                unauthorize,
                logout 
            } = this.props.dashboard_data

        if(changeStatusSuccess){

            this.props.handleFilterStatus({ tower: filterTower, floor: filterFloor, layout: filterType, availibility: filterStatus })

        }

        return (
            <div>

                <ProgressDialog
                        title="Please Wait..."
                        open={loading}
                    />

                <UnauthorizDialog 
                        open={unauthorize}
                        handleClose = { this.redirctToLogin }
                    />
                {
                    logout && (
                        <Redirect from='admin/dashboard' to='admin/login' />
                    )
                }

                <Card>
                    
                    <CardBody>

                    <GridContainer justify='center'>
                        <GridItem xs={12} sm={12} md={3} >
                        <FormControl className={classes.formControl} fullWidth={true}>
                            <InputLabel>Tower</InputLabel>
                            <Select
                                native
                                onChange={this.handleFilterTower()}
                                fullWidth={true}
                                inputProps={{
                                name: 'tower',
                                id: 'age-native-simple',
                                }}
                            >
                                <option value="all" >All</option>
                                <option value="A1">A1</option>
                                <option value="B2">B1</option>
                                <option value="B2">B2</option>
                                <option value="B2">B3</option>
                                <option value="B2">B4</option>
                            </Select>
                        </FormControl>
                            
                        </GridItem>

                        <GridItem xs={12} sm={12} md={3} >
                        <FormControl className={classes.formControl} fullWidth={true}>
                            <InputLabel>Floor</InputLabel>
                            <Select
                                native
                                onChange={this.handleFilterFloor()}
                                inputProps={{
                                name: 'floor',
                                id: 'age-native-simple',
                                }}
                            >
                                <option value="all" >All</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                            </Select>
                        </FormControl>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={3} >
                        <FormControl className={classes.formControl} fullWidth={true}>
                            <InputLabel>Type</InputLabel>
                            <Select
                                native
                                onChange={this.handleFilterType()}
                                inputProps={{
                                name: 'tower',
                                id: 'age-native-simple',
                                }}
                            >
                                <option value="all" >All</option>
                                <option value="ST">ST</option>
                                <option value="STB">STB</option>
                                <option value="T1">T1</option>
                                <option value="T2">T2</option>
                                <option value="T3">T3</option>
                            </Select>
                        </FormControl>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={3} >
                        <FormControl className={classes.formControl} fullWidth={true}>
                            <InputLabel>Status</InputLabel>
                            <Select
                                native
                                onChange={this.handleFilterStatus()}
                                inputProps={{
                                name: 'tower',
                                id: 'age-native-simple',
                                }}
                            >
                                <option value="all">All</option>
                                <option value="yes">Available</option>
                                <option value="booked">Booked</option>
                                <option value="no">Paid</option>
                            </Select>
                        </FormControl>
                        </GridItem>

                    </GridContainer>

                    <Table
                        tableHeaderColor="warning"
                        tableHead={["Tower", "Floor","Layout", "Room No","Updated Time","Updated By", "Status",]}
                        tableData={rooms}
                        onItemClick = {this.handleOnItemClick}
                        chnageStatus = {this.handleStatusChange}
                        status = 'Yes'
                        />
                    
                    {
                        rooms.length === 0 && (
                            <div className={classes.messageContainer}>
                                <h3 className={classes.message}>
                                    No Unit Avaiblable
                                </h3>
                            </div>
                           
                        )
                    }
                    </CardBody>

                </Card>

            </div>
        )
    }

    redirctToLogin = () => {

        sessionStorage.removeItem(JWT)

        this.props.doLogout(true)

    }

    handleOnItemClick = (id) => {
        // console.log('----- onItemClick ------')
        // console.log(id)
    }

    handleStatusChange = (props) => event =>{

        const { tower, floor, room_number } = props

        const status = event.target.value

        this.props.unitBlock({ tower, floor, room_number, status })

    }

    handleFilterTower = () => event => {

        const tower = event.target.value
        
        const { filterFloor, filterType, filterStatus } = this.props.dashboard_data

        this.props.handleFilterTower({ tower : tower, floor: filterFloor, layout: filterType, availibility: filterStatus })

    }

    handleFilterFloor = () => event => {

        // console.log('event.target.value', event.target.value)
        var floor ;
        if(event.target.value === 'all'){
            floor = event.target.value
        }else{
            floor = Number(event.target.value)
        }
        
        // console.log('floor', floor)
        // console.log('after change', floor)
        
        const { filterTower, filterType, filterStatus } = this.props.dashboard_data

        this.props.handleFilterFloor({ tower: filterTower, floor: floor, layout: filterType, availibility: filterStatus })

    }

    handleFilterType = () => event => {

        const type = event.target.value

        const { filterTower, filterFloor, filterStatus } = this.props.dashboard_data

        this.props.handleFilterType({ tower: filterTower, floor: filterFloor, layout: type, availibility: filterStatus })

    }

    handleFilterStatus = () => event => {

        const status = event.target.value

        const { filterTower, filterFloor, filterType } = this.props.dashboard_data

        this.props.handleFilterStatus({ tower: filterTower, floor: filterFloor, layout: filterType, availibility: status })

    }

}


const styles = theme => ({
    container: {
        margin: 20,
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cityloftImage: {
        width: 200,
        heigh: 50
    },
    formControl: {
        margin: theme.spacing.unit,
        
    },
    messageContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    message: {
        color: "#757575",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
})

MainDashboard.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {

    // console.log(' --- mapmapStateToProps @MainDashboard---')
    // console.log(state)
    // console.log(' --- mapmapStateToProps @MainDashboard---')

    return {
        login_data: state.login,
        dashboard_data: state.mainDashboard
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showMenu: (show) => {
            dispatch(
                showMenu(show)
            )
        },
       loadRoom: ({ jwt }) => {
           dispatch(
               loadRoom({ jwt })
            )
        },
        unitBlock: (props) => {
            dispatch(
                doUnitBlock(props)
            )
        },
        handleFilterTower: (props) => {
            dispatch(
                filterTower(props)
            )
        },
        handleFilterFloor: (props) => {
            dispatch(
                filterFloor(props)
            )
        },
        handleFilterType: (props) => {
            dispatch(
                filterType(props)
            )
        },
        handleFilterStatus: (props) => {
            dispatch(
                filterStatus(props)
            )
        },
        doLogout: (data) => {
            dispatch(
                logout(data)
            )
       }
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles) (MainDashboard)) 