
export const LOADING = 'loading'
export const SUCCESS = 'success'
export const ERROR = 'error'
export const UNAUTHORIZED = 'unauthorized'
export const LOG_OUT = 'logout'
export const UPDATE_LOGIN_STATE = 'update_login'

/**
 * Main
 */
export const SHOW_MENU = 'show_menu'

/**
 * Login
 */
export const USER_NAME = 'user_name'
export const PASSWORD = 'password'
export const LOGIN = 'login'

/**
 * Dashboard
 */
export const LOAD_ROOM ='load_room'
export const CHANGE_STATUS = 'change_status'
export const FILTER_TOWER = 'filter_tower' 
export const FILTER_FLOOR = 'filter_floor' 
export const FILTER_TYPE = 'filter_type'
export const FILTER_STATUS = 'filter_status' 

/**
 * Booking
 */
export const LOAD_BOOKING = 'load_bookings'
export const UPDATE_RELOAD_STATUS = 'update_booking_status'
export const UPDATE_DEPOSIT = 'update_deposit'
export const UPDATE_ROOM = 'update_room'
export const CHANGE_CONFIRMATION_NUMBER = 'change_confirmation_number'
export const OPEN_EDIT_DIALOG = 'open_edit_dialog'
export const SELECT_TOWER = 'select_tower'
export const SELECT_FLOOR = 'select_floor'
export const SELECT_ROOM_NO = 'select_room_no'
export const ROOM_NOT_AVAILABLE = 'room_not_available'
export const CLOSE_ERROR_DIALOG = 'close_error_dialog'
export const OPEN_UPDATE_DEPOSIT_DIALOG = 'open_deposit_dialog'
export const CHANGE_DEPOSIT = 'change_deposit'
export const AVAILABLE_ROOMS = 'available_rooms'


/**
 * user
 */
export const LOAD_USER = 'load_user'
export const LOAD_NRC_DATA = 'load_nrc'
export const OPEN_ADD_USER_DIALOG = 'open_add_user'
export const NRC_PRE_NUMBER = 'nrc_pre_number'
export const NRC_BODY1 = 'nrc_body1'
export const NRC_BODY2 = 'nrc_body2'
export const NRC_BODY3 = 'nrc_body3'
export const NRC_NATIONALITY = 'nrc_nationality'
export const NRC_NUMBER = 'nrc_number'
export const CUSTOMER_NAME = 'customer_name'
export const ELIGABLE_UNIT = 'eligable_unit'
export const ADD_USER = 'add_user'
export const UPDATE_ADD_USER_STATUS = 'update_add_user_status'

/**
 * enquirys
 */
export const LOAD_ENQUIRYS = 'load_enquiry'
export const OPEN_ADD_SH_BOOKING_DIALOG = 'open_add_sh_booking'
export const NRC = 'nrc'
export const TOWER = 'tower'
export const FLOOR = 'floor'
export const UNIT = 'unit'
export const CHOOSE_ROOM = 'choose_room'
export const BOOKING_USER = 'booking_user'